<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="collegeinfos"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="collegeinfoEdit(item.collegeinfo_code)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editcollegeinfodialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editcollegeinfodialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcollegeinfoform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <h2> {{ editcollegeinfo.collegeinfo_code}} : {{ editcollegeinfo.college_name }}</h2>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ผู้อำนวยการ"
                        v-model="editcollegeinfo.collegeinfo_director"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เบอร์โทร ผอ."
                        v-model="editcollegeinfo.collegeinfo_directorphone"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ที่อยู่วิทยาลัย"
                        v-model="editcollegeinfo.collegeinfo_address"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เบอร์โทรวิทยาลัย"
                        v-model="editcollegeinfo.collegeinfo_phone"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="FAX"
                        v-model="editcollegeinfo.collegeinfo_fax"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="Email วิทยาลัย"
                        v-model="editcollegeinfo.collegeinfo_email"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editcollegeinfodialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcollegeinfoSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addcollegeinfodialog: false,
      editcollegeinfodialog: false,
      deletecollegeinfodialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      collegeinfos: [],
      addcollegeinfo: {},
      editcollegeinfo: {},
      collegeinfos: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "ชื่อสถานศึกษา", align: "left", value: "college_name" },
        { text: "ผู้อำนวยการ", align: "left", value: "collegeinfo_director" },
        { text: "เบอร์โทร", align: "left", value: "collegeinfo_directorphone" },
        { text: "ที่อยู่", align: "left", value: "collegeinfo_address" },
        {
          text: "เบอร์โทรสถานศึกษา",
          align: "left",
          value: "collegeinfo_phone"
        },
        { text: "FAX", align: "left", value: "collegeinfo_fax" },
        { text: "E-mail", align: "left", value: "collegeinfo_email" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
        /*   { text: "ลบ", align: "center", value: "action_s" , icon: "mdi-delete-forever" }, */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      collegeinfo: {},
      provinces: [],
      prefectures: [],
      collegeinfotypes: [],
      collgegs: [],
      collegeinfostatus: [],
      regions: [],
      region_ena: true
    };
  },
  async mounted() {
    await this.collegeinfoQueryAll();

    let result_prefec = await this.$http.post("prefecture.php", {
      ApiKey: this.ApiKey
    });
    this.prefectures = result_prefec.data;

    let result_provin = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result_provin.data;

    let result_collegeinfoty = await this.$http.post("collegeinfo.php", {
      ApiKey: this.ApiKey
    });
    this.collegeinfotypes = result_collegeinfoty.data;

    let result_region = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result_region.data;

    let user_status_result;
    user_status_result = await this.$http.post(
      "crud_user_status.php?crud=read",
      {
        ApiKey: this.ApiKey
      }
    );
    this.userstatus = user_status_result.data;
  },

  methods: {
    async collegeinfoQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("collegeinfo.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.collegeinfos = result.data;
    },

    async collegeinfoAdd() {
      this.addcollegeinfo = {};
      this.addcollegeinfodialog = true;
    },
   

    async collegeinfoEdit(collegeinfo_code) {
      let result = await this.$http.post("collegeinfo.php", {
        ApiKey: this.ApiKey,
        collegeinfo_code: collegeinfo_code
      });
      this.editcollegeinfo = result.data;
      this.editcollegeinfodialog = true;
    },

    async editcollegeinfoSubmit() {
      if (this.$refs.editcollegeinfoform.validate()) {
        this.editcollegeinfo.ApiKey = this.ApiKey;
       let result = await this.$http.post(
          "collegeinfo.update.php",
          this.editcollegeinfo
        );
        
        
        if (result.data.status) {
          this.collegeinfoQueryAll();
          this.snackbar.icon = "mdi-checkbox-marked";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
        } else {
          this.snackbar.icon = "mdi-close-box";
          this.snackbar.color = "red";
          this.snackbar.text = "บันทึกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.editcollegeinfodialog = false
      }
    },   
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
